import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { getImage } from 'gatsby-plugin-image'

import onPageRender from '../hocs/onPageRender'
import Instagram from '../components/Instagram/Instagram'
import SectionTitle, {
  TITLE_TYPES,
} from '../components/SectionTitle/SectionTitle'
import LinkedCategory from '../components/LinkedCategory/LinkedCategory'
import HomeSlider from '../components/HomeSlider/HomeSlider'
import HomeIntro from '../components/HomeIntro/HomeIntro'
import Seo from '../components/Seo/Seo'

const Homepage = ({ data }) => {
  const {
    page: {
      data: {
        categories,
        slider,
        intro: { text: introText },
        seo_meta_title: seoTitle,
        seo_meta_description: { text: seoDescription },
      },
    },
  } = data
  return (
    <>
      <Seo title={seoTitle} description={seoDescription} />
      <HomeSlider slider={slider} />
      <HomeIntro text={introText} />
      <SectionTitle
        translationId="ourProducts"
        type={TITLE_TYPES.HANDWRITTEN_ALPHA}
      />
      <div className="flex justify-center">
        <div className="w-full xs:max-w-sm xs:px-4 2sm:max-w-md sm:max-w-xl md:max-w-3xl lg:max-w-5xl 3xl:max-w-6xl">
          {categories.map(({ category }, i) => {
            const { document } = category
            const { id, fields } = document
            const { pagePath } = fields
            const {
              title: { text: title },
              hero_image,
              active_category,
              description: { text: description },
            } = document.data
            const heroImage = getImage(hero_image.localFile)
            return (
              <div key={id} className={i < categories.length ? 'mb-16' : ''}>
                <LinkedCategory
                  index={i}
                  title={title}
                  description={description}
                  pagePath={pagePath}
                  heroImage={heroImage}
                  comingSoon={active_category === false}
                />
              </div>
            )
          })}
        </div>
      </div>
      {/* <Instagram /> */}
    </>
  )
}

Homepage.propTypes = {
  data: PropTypes.object.isRequired,
  addVariantToCart: PropTypes.func.isRequired,
}

export default withPreview(onPageRender(Homepage))

export const query = graphql`
  query($id: String!) {
    page: prismicHomepage(id: { eq: $id }) {
      data {
        title {
          text
        }
        slider {
          slider_image {
            localFile {
              id
              childImageSharp {
                gatsbyImageData(
                  quality: 75
                  placeholder: NONE
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        intro {
          text
        }
        categories {
          category {
            document {
              ... on PrismicCategory {
                id
                data {
                  title {
                    text
                  }
                  description {
                    text
                  }
                  hero_image {
                    ...FluidSquareImage
                  }
                  active_category
                }
                fields {
                  pagePath
                }
              }
            }
          }
        }
        seo_meta_title
        seo_meta_description {
          text
        }
      }
    }
  }
`
