import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { Translate } from 'react-localize-redux'

const getBackgroundImage = index => {
  switch (index) {
    case 1:
      return (
        <StaticImage
          quality={100}
          src="./assets/home-products-2.png"
          alt=""
          className="select-none"
        />
      )
    case 2:
      return (
        <StaticImage
          quality={100}
          src="./assets/home-products-3.png"
          alt=""
          className="select-none"
        />
      )
    default:
      return (
        <StaticImage
          quality={100}
          src="./assets/home-products-1.png"
          alt=""
          className="select-none"
        />
      )
  }
}

const LinkedCategory = ({
  title,
  description,
  heroImage,
  pagePath,
  index,
  comingSoon,
}) => {
  const descOrder = index % 2 !== 0 ? ' md:order-first' : ''

  if (comingSoon) {
    return (
      <div className="relative">
        <div className="absolute inset-0 flex flex-col items-center justify-center p-6 xs:p-8 md:p-12 3xl:p-16 z-10 text-center">
          <div className="xs:mt-8_ lg:mt-16_ 3xl:mt-20_ text-xl xs:text-2xl lg:text-4xl uppercase tracking-tight leading-none">
            {title}
          </div>
          {description && description !== '' && (
            <div className="mt-6 text-sm 3xl:text-base max-w-xs leading-tight">
              {description}
            </div>
          )}
          <div className="mt-3 text-5xl xl:text-6xl tracking-tight font-script text-center">
            Coming soon!
          </div>
        </div>
        <div className="hidden sm:block">
          <StaticImage
            alt=""
            src="./assets/coming-soon-1.png"
            placeholder="none"
            className="select-none"
            quality={100}
          />
        </div>
        <div className="sm:hidden">
          <StaticImage
            quality={100}
            src="./assets/home-products-3.png"
            alt=""
            className="select-none"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="sm:flex flex-wrap relative md:-mx-1">
      <div className="sm:w-2/3 md:w-1/2 md:px-1">
        <GatsbyImage image={heroImage} alt={title} />
      </div>
      <div
        className={`sm:w-2/3 sm:ml-auto sm:-mt-16 md:m-0 md:w-1/2 md:px-1 relative${descOrder}`}
      >
        <div className="absolute inset-0 flex flex-col items-center md:items-start justify-center md:justify-between p-6 xs:p-8 md:p-12 3xl:p-16 z-10 text-center md:text-left">
          <div className="xs:mt-8 lg:mt-16 3xl:mt-20 text-xl xs:text-2xl lg:text-4xl uppercase tracking-tight leading-none">
            {title}
          </div>
          <div className="mt-6 md:mb-auto text-sm 3xl:text-base max-w-xs leading-tight">
            {description}
          </div>
          <div className="mt-6 lg:mb-3 relative inline-flex">
            <div className="absolute inset-0 flex justify-center items-center z-10">
              <span className="text-sm lg:text-base">
                <Translate id="shopCollection" />
              </span>
            </div>
            <StaticImage
              alt=""
              src="./assets/home-products-button-bg.png"
              layout="fixed"
              width={175}
              placeholder="none"
              className="select-none"
              quality={100}
            />
          </div>
        </div>
        {getBackgroundImage(index)}
      </div>
      <Link to={`/${pagePath}`} className="absolute inset-0 z-20" />
    </div>
  )
}

LinkedCategory.propTypes = {
  heroImage: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  pagePath: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  comingSoon: PropTypes.bool,
}

LinkedCategory.defaultProps = {
  comingSoon: false,
}

export default LinkedCategory
