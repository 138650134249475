import React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'

const HomeIntro = ({ text }) => {
  return (
    <div className="flex justify-center my-24 px-6">
      <div className="w-full max-w-lg 3xl:max-w-xl relative flex justify-center items-center">
        <div className="absolute inset-0 flex justify-center items-center z-10">
          <div className="-mt-4 text-center leading-tight text-sm sm:text-base 3xl:text-lg">
            {text}
          </div>
        </div>
        <StaticImage
          src="./assets/home-intro-bg.png"
          width={300}
          alt=""
          className="select-none"
          loading="eager"
          placeholder="none"
        />
      </div>
    </div>
  )
}

HomeIntro.propTypes = {
  text: PropTypes.string,
}

HomeIntro.defaultProps = {
  text: '',
}

export default HomeIntro
