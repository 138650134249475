import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import withInstaNodes from '../../hocs/withInstaNodes'
import { image } from './Instagram.module.scss'
import Container from '../Container/Container'
import InstagramIcon from '../../icons/InstagramIcon'

/**
 * HOW TO GENERATE TOKEN
 * https://github.com/oorestisime/gatsby-source-instagram#instagram-graph-api-token
 */

SwiperCore.use([Pagination])

const MobileInsta = ({ nodes }) => (
  <>
    <div className="flex justify-center mb-10">
      <a
        href="https://www.instagram.com/cabuyahandmade"
        target="_blank"
        rel="noreferrer noopener"
        className="flex items-center uppercase tracking-widest text-xs"
      >
        <InstagramIcon width="16" height="16" color="#5A754D" />
        <span className="ml-2">@cabuyahandmade</span>
      </a>
    </div>
    <Swiper
      spaceBetween={20}
      pagination={{
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      }}
      breakpoints={{
        500: {
          slidesPerView: 2,
        },
      }}
    >
      {nodes.map(({ id, localFile }) => {
        return (
          <SwiperSlide key={id}>
            <a
              href={`https://www.instagram.com/p/${id}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <GatsbyImage
                image={getImage(localFile)}
                alt=""
                backgroundColor="#F0D7C3"
              />
            </a>
          </SwiperSlide>
        )
      })}
    </Swiper>
    <div className="swiper-pagination"></div>
  </>
)

const getImageByIndex = (nodes, index) => {
  const node = nodes[index]
  if (!node) return null
  return (
    <a
      href={`https://www.instagram.com/p/${node.id}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      <GatsbyImage image={getImage(node.localFile)} className={image} alt="" />
    </a>
  )
}

const DesktopInsta = ({ nodes }) => {
  return (
    <Container hasMarginBottom={false}>
      <div className="flex justify-center mb-10">
        <a
          href="https://www.instagram.com/cabuyahandmade"
          target="_blank"
          rel="noreferrer noopener"
          className="flex items-center uppercase tracking-widest text-xs"
        >
          <InstagramIcon width="16" height="16" color="#E2644C" />
          <span className="ml-2">@cabuyahandmade</span>
        </a>
      </div>
      <div className="flex flex-wrap justify-center items-center -mx-4">
        <div className="w-1/3 lg:w-2/12 px-4">{getImageByIndex(nodes, 0)}</div>
        <div className="w-1/3 lg:w-2/12 px-4">
          {getImageByIndex(nodes, 1)}
          <div className="my-4 flex justify-end">
            <div className="w-1/2">{getImageByIndex(nodes, 2)}</div>
          </div>
        </div>
        <div className="w-1/3 lg:w-2/12 px-4">{getImageByIndex(nodes, 3)}</div>
        <div className="w-1/3 lg:w-4/12 px-4 self-end">
          {getImageByIndex(nodes, 4)}
        </div>
        <div className="w-1/3 lg:w-2/12 px-4 self-end">
          {getImageByIndex(nodes, 5)}
        </div>
      </div>
      <div className="pt-16 text-5xl xl:text-6xl tracking-tight font-script text-terracotta text-center">
        <a
          href="https://www.instagram.com/explore/tags/ecuadorianlegacy/"
          target="_blank"
          rel="noreferrer noopener"
        >
          #ecuadorianlegacy
        </a>
      </div>
    </Container>
  )
}

const Instagram = ({ nodes }) => {
  if (!nodes) return null

  return (
    <div className="pb-10 md:py-16 md:mt-20 md:bg-almond">
      <div className="md:hidden">
        <MobileInsta nodes={nodes} />
      </div>
      <div className="hidden md:block">
        <DesktopInsta nodes={nodes} />
      </div>
    </div>
  )
}

Instagram.propTypes = {
  nodes: PropTypes.array.isRequired,
}

export default withInstaNodes(Instagram)
