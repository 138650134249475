import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { container } from './HomeSlider.module.scss'

SwiperCore.use([Autoplay])

const HomeSlider = ({ slider }) => {
  const hasRendered = useRef()

  useEffect(() => {
    hasRendered.current = true
  }, [])

  if (!slider.length) return null

  return (
    <div className="bg-almond">
      <div
        className={container}
        style={{
          opacity: hasRendered && hasRendered.current ? '1' : '0',
        }}
      >
        <Swiper
          autoplay={{
            disableOnInteraction: false,
          }}
          loop
          breakpoints={{
            1024: {
              speed: 1000,
            },
          }}
        >
          {slider.map(({ slider_image: { localFile, alt = '' } }) => {
            if (!localFile) return null
            return (
              <SwiperSlide key={localFile.id}>
                <div className="absolute inset-0">
                  <GatsbyImage
                    image={getImage(localFile)}
                    alt={alt}
                    loading="eager"
                    className="static block select-none"
                    objectFit="cover"
                    imgStyle={{ width: '100%', height: '100%' }}
                    style={{ width: '100%', height: '100%' }}
                    backgroundColor="#F0D7C3"
                  />
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div
          className="absolute right-0 bottom-0 left-0 pb-8 text-center leading-none xs:text-lg 2sm:text-2xl sm:text-3xl md:text-4xl lg:text-6xl 2xl:text-7xl tracking-tighter text-white uppercase z-10"
          style={{ textShadow: '0 2px 4px rgba(0,0,0,0.10)' }}
        >
          Sisana
          <div className="text-xs md:text-base lg:text-lg tracking-normal">
            New Collection
          </div>
        </div>
        <div className="hidden sm:block absolute bottom-0 right-0 mr-32 mb-4 2xl:mb-5 uppercase text-xs text-white transform rotate-90 origin-top-right translate-x-full z-10">
          Made in Ecuador
        </div>
      </div>
    </div>
  )
}

HomeSlider.propTypes = {
  slider: PropTypes.array,
}

HomeSlider.defaultProps = {
  slider: [],
}

export default HomeSlider
